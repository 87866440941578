<template>
  <div>
    <div class="page-name">
      <span>
        <i class="fas fa-map-marker-alt"></i>

        {{ i18n("Custom.ExpatriateLocationMaintenance") }}
      </span>
    </div>

    <div class="text-right mb-2">
      <a class="btn btn-blue" @click.prevent="addPlace()">{{ i18n("Basic.Add") }}</a>
    </div>
    <!-- <vue-bootstrap4-table
      :rows="placeList"
      :columns="columns"
      :config="config"
      :classes="classes"
    >
      <template slot="name" slot-scope="props">
        <el-input v-model="props.row.Name" placeholder=""></el-input>
      </template>
      <template slot="isEnabled" slot-scope="props">
        <el-switch
          v-model="props.row.IsEnabled"
          active-color="#007bff"
          inactive-color="#cccccc"
        >
        </el-switch>
      </template>
    </vue-bootstrap4-table> -->

    <el-table
      :data="placeList.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
      style="width: 100%; margin-bottom: 20px"
      stripe
    >
      <el-table-column :label="this.i18n('Custom.PlaceName')" sortable>
        <template slot-scope="props">
          <el-input v-model="props.row.Name" placeholder=""></el-input>
        </template>
      </el-table-column>
      <el-table-column :label="this.i18n('Custom.WhetherToEnable')" sortable align="center"
        width="150">
        <template slot-scope="props">

          <el-switch
            v-model="props.row.IsEnabled"
            active-color="#007bff"
            inactive-color="#cccccc"
          >
          </el-switch>

        </template>
      </el-table-column>
      <el-table-column
       align="center"
        prop="EditorName"
        :label="this.i18n('Custom.Modifier')"
        width="150"
        sortable
      ></el-table-column>
      <el-table-column
       align="center"
        prop="CreatorName"
        :label="this.i18n('Basic.AddEmployee')"
        sortable
        width="150"
      ></el-table-column>
    </el-table>

    <el-pagination
      v-if="placeList.length > 0"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      background
      layout="prev, pager, next"
      :total="placeList.length"
    >
    </el-pagination>

    <div class="text-center">
      <a class="btn btn-blue" @click.prevent="savePlaceList()">{{
        i18n("Basic.Save")
      }}</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      screen: null, // loading遮罩
      placeList: [],
      pageSize: 10,
      currentPage: 1,
      // config: {
      //   card_mode: false,
      //   show_refresh_button: false,
      //   show_reset_button: false,
      //   per_page: 10,
      //   per_page_options: [10, 25, 50],
      //   global_search: {
      //     visibility: false,
      //   },

      // },
      // classes: {
      //   table: "table-light table-striped",
      // },
    };
  },
  computed: {
    // columns() {
    //   const col = [
    //     {
    //       label: this.i18n("Custom.PlaceName"),
    //       name: "Name",
    //       sort: true,
    //       slot_name: "name",
    //     },
    //     {
    //       label: this.i18n("Custom.WhetherToEnable"),
    //       name: "IsEnabled",
    //       sort: true,
    //       slot_name: "isEnabled",
    //     },
    //     {
    //       label: this.i18n("Custom.Modifier"),
    //       name: "EditorName",
    //       sort: true,
    //     },
    //     {
    //       label: this.i18n("Basic.AddEmployee"),
    //       name: "CreatorName",
    //       sort: true,
    //     },
    //   ];
    //   return col;
    // },
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    handleSizeChange(val) {
      this.pageSize = val;
    },

    handleCurrentChange(val) {
      this.currentPage = val;
    },

    // 取得外派地列表
    getPlaceList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Org/Expat/Place`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.placeList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    // 儲存外派地列表
    savePlaceList() {
      const vm = this;
      const api = `${window.BaseUrl.api}/Org/Expat/Place`;

      vm.$http
        .post(api, vm.placeList)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            vm.getPlaceList();
            this.$notify({
              message: response.data.Data,
              type: "success",
            });
          }
        })
        .catch((error) => {
          vm.$refs.upload.clearFiles();
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
        });
    },
    // 新增外派地
    addPlace() {
      const vm = this;
      const newPlace = {
        Name: null,
        IsEnabled: true,
      };
      vm.placeList.push(newPlace);
    },
  },
  created() {
    this.getPlaceList();
  },
};
</script>
